<template>
    <div>
        <div class="opModal">
            <div class="modalHeader">
                <h4>
                    <localized-text localizedKey="Nuovo appuntamento" text="Nuovo appuntamento"></localized-text>
                </h4>
            </div>
            <div class="modalBody">
                <div class="form-group mt-4 mb-0">
                    <label>
                        <localized-text localizedKey="Cerca per nome/cognome" text="Cerca per nome/cognome"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="searchText" />
                </div>

                <div class="list scrollListContainer">
                    <div class="listItem" v-if="clientiList.length == 0">
                        <localized-text localizedKey="Scrivi per cercare tra i tuoi clienti" text="Scrivi per cercare tra i tuoi clienti"></localized-text>
                    </div>
                    <div class="scrollListItem pointer" v-for="(item, i) in (clientiList as OM.NameIdentifier[])" :key="i" @click="selectCliente(item)"
                        :class="{ 'selected': vm.cliente == item }">
                        <div class="text-green fs-4 fw-semibold">{{item.name}}</div>
                    </div>
                </div>
                <template v-if="vm.cliente && tickets.length == 0">
                    <localized-text localizedKey="Questo cliente non ha voucher prenotazione disponibili" text="Questo cliente non ha voucher prenotazione disponibili"></localized-text>
                </template>
                <template v-else-if="tickets.length > 0">    
                    <div class="mt-4">
                        <div class="fw-bold">
                            <localized-text localizedKey="Seleziona la data" text="Seleziona la data"></localized-text>
                        </div>
                    </div>
                    <calendar v-slot="data" class="month smallCalendar mt-3" :show-mode-changer="false" :scroll-to-hour="false" :start-mode="'month'">
                        <div class="calendarDay pointer" :class="[day.classes, { selected: day.date == vm.date }]" v-for="(day, i) in data.days" :key="i" 
                        @click="selectDate(day)">
                        <div>{{$filters.date(day.date, 'DD')}}</div>
                    </div>
                    </calendar>
                    <div class="d-flex align-items-center mt-2">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Ora inizio" text="Ora inizio"></localized-text>
                            </label>
                            <input type="time" class="form-control" v-model="vm.fasciaOraria.from" />
                        </div>
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Ora fine" text="Ora fine"></localized-text>
                            </label>
                            <input type="time" class="form-control" v-model="vm.fasciaOraria.to" />
                        </div>
                    </div>
                </template>

            </div>
            <div class="modalFooter text-end">
                <button type="button" class="btn btn-primary" @click="save" :disabled="!canSave">
                    <localized-text localizedKey="Salva" text="Salva"></localized-text>
                </button>
                <button type="button" class="btn btn-link text-danger" @click="$emit('close')">
                    <localized-text localizedKey="Annulla" text="Annulla"></localized-text>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import { BaseUserClient, BookingClient, BookingVoucherClient } from '@/services/Services';

@Options({})
export default class nuovoAppuntamentoModal extends Vue {

    @Prop() customer: OM.Lead;
    @Prop() doctorIdentifier: string;

    searchText: string = "";
    clientiList: OM.NameIdentifier[] = [];
    tickets: OM.BookingVoucher[] = [];

    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();

    created(){
        BaseUserClient.getNameIdentifier(this.doctorIdentifier)
        .then(x => {
            this.vm.user = x;
        })
        if(this.customer){
            this.vm.cliente = { identifier: this.customer.identifier, name: this.customer.personalData.completeName };
        } else {
            this.vm.cliente = null;
        }
    }

    debuncio: any = null;
    @Watch('searchText')
    onSearchTextChange(next, prev){
        clearTimeout(this.debuncio);
        this.debuncio = setTimeout(() => {
            this.vm.cliente = null;
            BaseUserClient.searchClienteByDottore(this.searchText, this.vm.user.identifier)
            .then(x => {
                this.clientiList = x;
            })
        }, 600);
    }

    selectCliente(item: OM.NameIdentifier){
        BookingVoucherClient.getAvailableBookingVoucher(store.state.selectedProject, item.identifier)
        .then(x => {
            this.vm.cliente = item;
            this.tickets = x;
            if(this.tickets.length > 0){
                this.vm.ticketPrenotazioneIdentifier = this.tickets[0].identifier;
            }
        })
    }
    
    selectDate(day){
        this.vm.date = day.date;
    }


    get canSave(){
        return this.vm.cliente && this.vm.ticketPrenotazioneIdentifier && this.vm.date && (this.vm.fasciaOraria && this.vm.fasciaOraria.from && this.vm.fasciaOraria.to);
    }

    save(){
        BookingClient.makePrenotazione(this.vm)
        .then(x => {
            ModalServices.alertModal(
                LocalizationServices.GetLocalizedValue('Appuntamento confermato!', 'Appuntamento confermato!'),
                LocalizationServices.GetLocalizedValue('Appuntamento registrato con successo', 'Appuntamento registrato con successo'),
                null, 
                () => {
                    this.$emit('close');
                }
            )
        })
    }
    
}

</script>

<style scoped>


</style>