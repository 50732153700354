<template>
    <component :is="type" v-html="translatedText"></component>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import store from '@/store';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class LocalizedText extends Vue {

    @Prop({ default: "span"}) type: string;
    @Prop({ default: "" }) localizedKey: string;
    @Prop({ default: "" }) text: string;
    @Prop({ default: "" }) prevText: string;
    @Prop({ default: "" }) afterText: string;
    @Prop({ default: "" }) object: any;

    @Prop({
        default: 0
    }) numberAsterisk: number;

    localizedValues: OM.LocalizedEntity[] = [];
    translatedText: string = "";

    @Watch('localizedKey')
    @Watch('prevText')
    @Watch('afterText')
    @Watch('object')
    onTextChange() {
        this.setLocalizedText();
    }

    unwatcher: () => void = null;
    mounted() {
        this.setLocalizedText();
        this.unwatcher = this.$store.watch(
            state => {
                return (state.loginData && state.user && state.user.preferredCulture) || 
                (state.selectedLanguage) ||
                "en-US"
            },
            () => { 
                this.setLocalizedText(); 
            }
        )
    }

    beforeUnmount(){
        this.unwatcher();
    }

    setLocalizedText() {
        let projectKey = "SHC" + "_" + this.localizedKey;
        this.translatedText = LocalizationServices.GetLocalizedValue(projectKey, this.text, this.afterText, this.prevText, this.object);
    }
}
</script>
<style scoped>
</style>