import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8080a00a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal appuntamentiOfDateModal" }
const _hoisted_2 = { class: "modalHeader flex-column" }
const _hoisted_3 = { class: "bigTitle" }
const _hoisted_4 = { class: "midTitle text-orange mt-2" }
const _hoisted_5 = {
  key: 0,
  class: "midTitle"
}
const _hoisted_6 = { class: "modalBody container" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "midTitle"
}
const _hoisted_10 = {
  key: 0,
  class: "mt-2 text-center"
}
const _hoisted_11 = {
  key: 0,
  class: "form-group mt-2"
}
const _hoisted_12 = { class: "form-group mt-2" }
const _hoisted_13 = { class: "form-group mt-2" }
const _hoisted_14 = { class: "form-group mt-2" }
const _hoisted_15 = { class: "midTitle text-center mt-2" }
const _hoisted_16 = { class: "form-group mt-2" }
const _hoisted_17 = { class: "form-group mt-2" }
const _hoisted_18 = { class: "form-group mt-2" }
const _hoisted_19 = {
  key: 0,
  class: "text-danger fs-6"
}
const _hoisted_20 = { class: "form-group mt-2" }
const _hoisted_21 = { class: "modalFooter d-flex justify-content-between" }
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_autocomplete = _resolveComponent("autocomplete")!
  const _component_telephone = _resolveComponent("telephone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$filters.date(_ctx.date, 'DD MMMM YYYY')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$filters.timespan(_ctx.fasciaOraria.from)) + " - " + _toDisplayString(_ctx.$filters.timespan(_ctx.fasciaOraria.to)), 1),
      (_ctx.booking)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_localized_text, {
              localizedKey: "Stai spostando una prenotazione",
              text: "Stai spostando una prenotazione"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Progetto",
            text: "Progetto"
          })
        ]),
        _createElementVNode("input", {
          type: "text",
          class: "form-control",
          readonly: "true",
          value: _ctx.$store.state.selectedProject
        }, null, 8, _hoisted_8)
      ]),
      (!_ctx.vm.ticketPrenotazioneIdentifier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_localized_text, {
              localizedKey: "Per prenotare un appuntamento per un cliente devi avere un voucher prenotazione disponibile",
              text: "Per prenotare un appuntamento per un cliente devi avere un voucher prenotazione disponibile"
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.booking)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass([_ctx.mode == 'search' ? 'btn-primary' : 'btn-cancel', "btn"]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode = 'search'))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Cerca anagrafica",
                      text: "Cerca anagrafica"
                    })
                  ], 2),
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass([_ctx.mode == 'new' ? 'btn-primary' : 'btn-cancel', "btn ms-2"]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode = 'new'))
                  }, "Inserisci nuovo", 2)
                ]))
              : _createCommentVNode("", true),
            (_ctx.mode == 'search')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (!_ctx.booking)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("label", null, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Cerca cliente",
                            text: "Cerca cliente"
                          })
                        ]),
                        _createVNode(_component_autocomplete, {
                          list: _ctx.clientiForDropdown,
                          "search-callback": _ctx.searchCb,
                          modelValue: _ctx.selectedCliente,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCliente) = $event)),
                          "obj-key": 'name'
                        }, null, 8, ["list", "search-callback", "modelValue"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Nome",
                        text: "Nome"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      readonly: true,
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.clienteDetails.personalData.name) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.clienteDetails.personalData.name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Cognome",
                        text: "Cognome"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      readonly: true,
                      class: "form-control",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clienteDetails.personalData.surname) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.clienteDetails.personalData.surname]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Email",
                        text: "Email"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      readonly: true,
                      class: "form-control",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.clienteDetails.authData.username) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.clienteDetails.authData.username]
                    ])
                  ]),
                  _createVNode(_component_telephone, {
                    class: "maxw-350 mx-auto",
                    label: "Telefono",
                    readonly: true,
                    modelValue: _ctx.clienteDetails.contactData.telephone,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.clienteDetails.contactData.telephone) = $event))
                  }, null, 8, ["modelValue"])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Dati del cliente",
                      text: "Dati del cliente"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Nome",
                        text: "Nome"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.registerVm.name) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.registerVm.name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Cognome",
                        text: "Cognome"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.registerVm.surname) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.registerVm.surname]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Email",
                        text: "Email"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.registerVm.email) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.registerVm.email]
                    ]),
                    (!_ctx.isMailAvailable)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Email già registrata",
                            text: "Email già registrata"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_telephone, {
                    class: "maxw-350 mx-auto",
                    label: "Telefono",
                    modelValue: _ctx.registerVm.telephone,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.registerVm.telephone) = $event))
                  }, null, 8, ["modelValue"])
                ], 64)),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Note appuntamento",
                  text: "Note appuntamento"
                })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.vm.note) = $event))
              }, null, 512), [
                [_vModelText, _ctx.vm.note]
              ])
            ])
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-success",
        onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.prenota && _ctx.prenota(...args))),
        disabled: !_ctx.canNext
      }, [
        (!_ctx.booking)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: "Conferma prenotazione",
              text: "Conferma prenotazione"
            }))
          : (_openBlock(), _createBlock(_component_localized_text, {
              key: 1,
              localizedKey: "Sposta prenotazione",
              text: "Sposta prenotazione"
            }))
      ], 8, _hoisted_22),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}