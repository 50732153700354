<template>
    <div class="opModal appuntamentiOfDateModal">
        <div class="modalHeader flex-column">
            <div class="bigTitle">{{$filters.date(date, 'DD MMMM YYYY')}}</div>
            <div class="midTitle text-orange mt-2">{{$filters.timespan(fasciaOraria.from)}} - {{$filters.timespan(fasciaOraria.to)}}</div>
            <div class="midTitle" v-if="booking">
                <localized-text localizedKey="Stai spostando una prenotazione" text="Stai spostando una prenotazione"></localized-text>
            </div>
        </div>
        <div class="modalBody container">
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
                </label>
                <input type="text" class="form-control" readonly="true" :value="$store.state.selectedProject" />
            </div>
            <template v-if="!vm.ticketPrenotazioneIdentifier">
                <div class="midTitle">
                    <localized-text localizedKey="Per prenotare un appuntamento per un cliente devi avere un voucher prenotazione disponibile" text="Per prenotare un appuntamento per un cliente devi avere un voucher prenotazione disponibile"></localized-text>
                </div>
            </template>
            <template v-else>
                <div class="mt-2 text-center" v-if="!booking">
                    <button type="button" :class="mode == 'search' ? 'btn-primary' : 'btn-cancel'" class="btn" @click="mode = 'search'">
                        <localized-text localizedKey="Cerca anagrafica" text="Cerca anagrafica"></localized-text>
                    </button>
                    <button type="button" :class="mode == 'new' ? 'btn-primary' : 'btn-cancel'" class="btn ms-2" @click="mode = 'new'">Inserisci nuovo</button>
                </div>
                <template v-if="mode == 'search'">
                    <div class="form-group mt-2" v-if="!booking">
                        <label>
                            <localized-text localizedKey="Cerca cliente" text="Cerca cliente"></localized-text>
                        </label>
                        <autocomplete :list="clientiForDropdown" :search-callback="searchCb" 
                            v-model="selectedCliente" :obj-key="'name'"></autocomplete>
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Nome" text="Nome"></localized-text>
                        </label>
                        <input type="text" :readonly="true" class="form-control" v-model="clienteDetails.personalData.name" />
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Cognome" text="Cognome"></localized-text>
                        </label>
                        <input type="text" :readonly="true" class="form-control" v-model="clienteDetails.personalData.surname" />
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Email" text="Email"></localized-text>
                        </label>
                        <input type="email" :readonly="true" class="form-control" v-model="clienteDetails.authData.username" />
                    </div>
                    <telephone class="maxw-350 mx-auto" label="Telefono" :readonly="true" v-model="clienteDetails.contactData.telephone"></telephone>
                </template>
                <template v-else>
                    <div class="midTitle text-center mt-2">
                        <localized-text localizedKey="Dati del cliente" text="Dati del cliente"></localized-text>
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Nome" text="Nome"></localized-text>
                        </label>
                        <input type="text" class="form-control" v-model="registerVm.name" />
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Cognome" text="Cognome"></localized-text>
                        </label>
                        <input type="text" class="form-control" v-model="registerVm.surname" />
                    </div>
                    <div class="form-group mt-2">
                        <label>
                            <localized-text localizedKey="Email" text="Email"></localized-text>
                        </label>
                        <input type="email" class="form-control" v-model="registerVm.email" />
                        <div class="text-danger fs-6" v-if="!isMailAvailable">
                            <localized-text localizedKey="Email già registrata" text="Email già registrata"></localized-text>
                        </div>
                    </div>
                    <telephone class="maxw-350 mx-auto" label="Telefono" v-model="registerVm.telephone"></telephone>
                </template>
                <div class="form-group mt-2">
                    <label>
                        <localized-text localizedKey="Note appuntamento" text="Note appuntamento"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="vm.note" />
                </div>
            </template>
        </div>
        <div class="modalFooter d-flex justify-content-between">
            <button type="button" class="btn btn-success" @click="prenota" :disabled="!canNext">
                <localized-text v-if="!booking" localizedKey="Conferma prenotazione" text="Conferma prenotazione"></localized-text>
                <localized-text v-else localizedKey="Sposta prenotazione" text="Sposta prenotazione"></localized-text>
            </button>
            <button type="button" class="btn btn-primary" @click="$emit('close')">
                <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, BaseVoucherClient, BookingClient, BookingVoucherClient, LoginClient } from '@/services/Services';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { validateEmail } from '@/utils';
import EventBus from '@/services/EventBus';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class prenotaPerClienteModal extends Vue {

    @Prop() doctorIdentifier: string;
    @Prop() customerIdentifier: string;
    @Prop() booking: OM.BookingForListVm;
    @Prop() voucher: OM.BookingVoucher;
    @Prop() fasciaOraria: OM.TimeTableItem;
    @Prop() date: Date;

    clientiForDropdown: OM.NameIdentifier[] = [];
    selectedCliente: OM.NameIdentifier = null;
    clienteDetails: OM.Customer = new OM.Customer();
    isMailAvailable: boolean = true;
    mode: string = "search";

    registerVm: OM.RegisterVm = new OM.RegisterVm();
    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();

    created(){

        // if(!(store.state.user).commercialeDiRiferimento){
        //     ModalServices.alertModal("Errore", "Non è stato associato il commerciale di riferimento. Contattare il supporto", "", () => {
        //         this.$opModal.closeAll();
        //     });
        // }
        if(this.booking){
            this.vm.prenotazioneIdentifier = this.booking.identifier;
        }

        if(this.voucher){
            this.vm.ticketPrenotazioneIdentifier = this.voucher.identifier;
            BaseUserClient.getNameIdentifier(this.customerIdentifier)
            .then(x => {
                this.selectedCliente = x;
            })
        } else {
            BookingVoucherClient.getAvailableBookingVoucher(store.state.selectedProject, this.customerIdentifier)
            .then(x => {
                if(x.length == 0){
                    return;
                }
                this.vm.ticketPrenotazioneIdentifier = x[0].identifier;
                BaseUserClient.searchCliente("", store.state.user.identifier)
                .then(x => {
                    this.clientiForDropdown = x;
                    if(this.$route.params.identifier as string){
                        this.selectedCliente = this.clientiForDropdown.find(x => x.identifier == this.$route.params.identifier as string);
                    }
                })
            })
        }
    }

    searchCb(input: string){
        return this.clientiForDropdown.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    @Watch('selectedCliente')
    onSelectedClienteChange(next, prev){
        this.vm.cliente = this.selectedCliente;
        BaseUserClient.getById(this.vm.cliente.identifier)
        .then(x => {
            this.clienteDetails = x;
        });
    }

    prenota(){
        if(!this.canNext){
            return;
        }

        BaseUserClient.getById(this.doctorIdentifier)
        .then(x => {

            this.registerVm.country = ""; //x.personalData.nazione;
            this.registerVm.referralCode = x.referralCode;
            this.registerVm.nomeCentro = x.personalData.completeName;

            let prom;
            if(this.mode == "new"){
                prom = LoginClient.register(this.registerVm);
            } else {
                prom = Promise.resolve();
            }
            prom.then(cliente => {
                if(cliente){
                    this.vm.cliente = {
                        identifier: cliente.identifier,
                        name: cliente.personalData.completeName
                    }
                } else {
                    this.vm.cliente = this.selectedCliente;
                }
                this.vm.date = this.date.toISOString();
                this.vm.fasciaOraria = this.fasciaOraria;
                this.vm.user = {
                    identifier: x.identifier,
                    name: x.personalData.completeName
                };

                if(this.booking){
                    BookingClient.spostaPrenotazione(this.vm)
                    .then(x => {
                        ModalServices.alertModal(
                            "",
                            LocalizationServices.GetLocalizedValue('Appuntamento spostato', 'Appuntamento spostato'),
                            null,
                            () => {
                                this.$opModal.closeAll();
                                EventBus.$emit('reloaddash');
                            }
                        )
                    })
                } else {
                    BookingClient.makePrenotazione(this.vm)
                    .then(x => {
                        ModalServices.alertModal(
                            'Appuntamento confermato!',
                            'Appuntamento registrato con successo',
                            null,
                            () => {
                                this.$opModal.closeAll();
                                EventBus.$emit('reloaddash');
                            }
                        )
                    })
                }
            })
        })
    }

    get canNext(){
        if(this.mode == 'new'){
            return this.isMailAvailable && this.registerVm.name && this.registerVm.surname && validateEmail(this.registerVm.email) && 
                this.registerVm.telephone.number && this.registerVm.telephone.prefix;
        } else {
            return !!this.selectedCliente;
        }
    }

    debuncio: any = null;
    @Watch('registerVm.email')
    onEmailChange(next, prev){
        clearTimeout(this.debuncio);
        this.debuncio = setTimeout(() => {
            LoginClient.checkEmailAvailable(this.registerVm.email)
            .then(x => {
                this.isMailAvailable = x;
            })
        }, 800);
    }

}

</script>

<style scoped>

.opModal {
    min-width: 500px;
    max-width: 1280px;
}

</style>