<template>
<div class="opModal">
    <div class="header">
        <h3>
            <localized-text localizedKey="Gestione orari prenotazione" text="Gestione orari prenotazione"></localized-text>
        </h3>
    </div>
    <div class="form-group">
        <label>
            <localized-text localizedKey="Seleziona professione" text="Seleziona professione"></localized-text>
        </label>
        <select class="form-control" v-model="selectedProfessione">
            <option v-for="(item, i) in professioni" :value="item">{{item.name}}</option>
        </select>
    </div>
    <hr/>
    <div class="body" v-if="config">
        <div class="form-group">
            <label>
                <localized-text localizedKey="Numero giorni preavviso" text="Numero giorni preavviso"></localized-text>
            </label>
            <input type="number" class="form-control" v-model="config.calendarConfig.advanceNoticeDays" />
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Numero giorni di distacco dalla visita precedente" text="Numero giorni di distacco dalla visita precedente"></localized-text>
            </label>
            <input type="number" class="form-control" v-model="config.calendarConfig.daysSinceLastBooking" />
        </div>
        <accordion>
            <template #title>
                <div>
                    <localized-text localizedKey="Orari della settimana" text="Orari della settimana"></localized-text>
                     ({{config.calendarConfig.weeklyTimeTable.length}})
                </div>
            </template>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <td>
                            <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Slot orari" text="Slot orari"></localized-text>
                            <br/>
                            <localized-text localizedKey="(selezionabili per appuntamento)" text="(selezionabili per appuntamento)"></localized-text>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-success" @click="addOrarioPrenotazione">+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in config.calendarConfig.weeklyTimeTable" :key="i">
                        <td>
                            <select v-model="item.dayOfTheWeek">
                                <option :value="null" disabled>Giorno</option>
                                <option v-for="(i, day) in daysOfWeek" :key="i" :value="i">{{day}}</option>
                            </select>
                        </td>
                        <td>
                            <div class="text-center">
                                <button type="button" class="btn btn-success" @click="addFasciaOraria(item)">+</button>
                            </div>
                            <div class="d-flex align-items-center justify-content-around" v-for="(fascia, i) in item.timeTable" :key="i">
                                <input type="time" v-model="fascia.from" />
                                <input type="time" v-model="fascia.to" />
                                <button type="button" class="btn btn-danger btn-sm" @click="removeFascia(fascia, item.timeTable)">-</button>
                            </div>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-danger" @click="removeOrarioPrenotazione(item)">- rimuovi giorno</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </accordion>
        <hr />
        <accordion>
            <template #title>
                <div>
                    <localized-text localizedKey="Giorni da escludere" text="Giorni da escludere"></localized-text>
                    ({{config.calendarConfig.daysToExclude.length}})
                </div>
            </template>
            <table class="table table-fixed table-striped" style="max-width: 720px;">
                <thead>
                    <tr>
                        <td>
                            <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Mese" text="Mese"></localized-text>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-success" @click="addGiornoDaEscludere">+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in config.calendarConfig.daysToExclude" :key="i">
                        <td>
                            <input type="number" placeholder="Giorno (numero)" class="form-control" v-model="item.day" />
                        </td>
                        <td>
                            <input type="number" placeholder="Mese (numero)" class="form-control" v-model="item.month" />
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-danger" @click="config.calendarConfig.daysToExclude.splice(i, 1)">-</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </accordion>
        <hr />
        <accordion>
            <template #title>
                <div>
                    <localized-text localizedKey="Periodi da escludere" text="Periodi da escludere"></localized-text>
                    ({{config.calendarConfig.periodsToExclude.length}})
                </div>
            </template>
            <table class="table table-fixed table-striped" style="max-width: 720px;">
                <thead>
                    <tr>
                        <td>
                            <localized-text localizedKey="Inizio" text="Inizio"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Fine" text="Fine"></localized-text>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-success" @click="addPeriodoDaEscludere">+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in config.calendarConfig.periodsToExclude" :key="i">
                        <td>
                            <input type="date" v-date="item.from" class="form-control" v-model="item.from" />
                        </td>
                        <td>
                            <input type="date" v-date="item.to" class="form-control" v-model="item.to" />
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-danger" @click="config.calendarConfig.periodsToExclude.splice(i, 1)">-</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </accordion>

        <hr/>
        <div class="mt-5 mw-700">
            <button type="button" class="btn btn-block btn-primary" @click="ok">
                <localized-text localizedKey="OK" text="OK"></localized-text>
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as CONST from '@/const';
import { JsonClone } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, BookingCalendarConfigClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class OrariPrenotazioneModal extends Vue {

    @Prop() userIdentifier: string;

    config: OM.BookingCalendarConfig = null;
    daysOfWeek = CONST.DayOfWeek.GetDictionary();
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];

    created(){
        BaseUserClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        this.config = null;
        BookingCalendarConfigClient.getByUser(this.userIdentifier, this.selectedProfessione.identifier)
        .then(x => {
            this.config = x;
        })
    }

    addOrarioPrenotazione(){
        let toPush = new OM.TimeTableConfig();
        this.config.calendarConfig.weeklyTimeTable.push(toPush);
    }

    addFasciaOraria(item: OM.TimeTableConfig){
        let toPush = new OM.TimeTableItem();
        item.timeTable.push(toPush);
    }

    removeOrarioPrenotazione(item: OM.TimeTableConfig){
        let index = this.config.calendarConfig.weeklyTimeTable.indexOf(item);
        this.config.calendarConfig.weeklyTimeTable.splice(index, 1);
    }

    removeFascia(fascia, fasceOrarie){
        let index = fasceOrarie.indexOf(fascia);
        fasceOrarie.splice(index, 1);
    }

    addGiornoDaEscludere(){
        let toPush = new OM.DayToExclude();
        this.config.calendarConfig.daysToExclude.push(toPush);
    }

    addPeriodoDaEscludere(){
        let toPush = new OM.PeriodToExclude();
        this.config.calendarConfig.periodsToExclude.push(toPush);
    }

    ok(){
        BookingCalendarConfigClient.save(this.config)
        .then(x => {
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
            this.$emit('close');
        })
    }

}
</script>
<style scoped>

.opModal {
    min-width: 320px;
    width: 50%;
}

</style>